import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { I18nContextProvider } from 'src/context/i18n';
import { AuthContextProvider, useAuthContext } from 'src/context/authContext';
import { AppNavigation } from 'src/features/appLayout';
import { PusherProvider } from 'src/features/pusher/PusherProvider';
import { MODAL_ROOT_ID } from 'src/shared/ui/modal/ModalDialog';
import { browserIsSafari } from 'src/shared/utils/common';
import { FallbackErrorPage } from 'src/shared/ui/layout/FallbackErrorPage';
import { FileUploadProgressModal } from 'src/features/appLayout/ui/FileUploadProgressModal';
import { SidebarContextProvider } from 'src/features/appLayout/SidebarContext';
import css from './RoutesHelpers.module.scss';
import { Unlock } from 'react-feather';
import { SUBSCRIPTION_PLAN } from 'src/pages/billing/model/model.types';
import { useIsMobile } from 'src/shared/hooks/useIsMobile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60,
      retry: false,
    },
  },
});

const NotificationsProvider: React.FC = ({ children }) => {
  if ('PushManager' in window || browserIsSafari()) {
    return <PusherProvider>{children}</PusherProvider>;
  }

  return <>{children}</>;
};

export const CommonRoute: React.FC = () => (
  <Sentry.ErrorBoundary
    fallback={({ error }) => <FallbackErrorPage error={error} />}
    onError={({ message }) => {
      message?.includes('Failed to fetch dynamically imported module') && window.location.reload();
    }}
  >
    <I18nContextProvider>
      <QueryClientProvider client={queryClient}>
        <Outlet />
        <ToastContainer />
        <ScrollRestoration />
        <ReactQueryDevtools initialIsOpen={false} position='top-right' />
      </QueryClientProvider>
    </I18nContextProvider>
  </Sentry.ErrorBoundary>
);

const TopRibbon: React.FC = () => {
  const { currentUser } = useAuthContext();
  const currentPlan = currentUser.currentPaymentPlan as SUBSCRIPTION_PLAN;
  const isFree = currentPlan === SUBSCRIPTION_PLAN.free;
  const isMobile = useIsMobile();

  if (isFree) {
    return (
      <div className={css.topRibbon}>
        <p className='mb-0'>You're on a limited Demo Plan</p>
        {isMobile ? (
          <a href='/membership' target='_blank'>
            Unlock all features
          </a>
        ) : (
          <div
            className={css.unlockFeatures}
            role='button'
            tabIndex={0}
            onClick={() => window.open(`/membership`)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                window.open(`/membership`);
              }
            }}
          >
            <Unlock size={16} /> Unlock all features
          </div>
        )}
      </div>
    );
  }

  return null;
};

export const AuthRoute: React.FC = () => {
  return (
    <AuthContextProvider>
      <NotificationsProvider>
        <SidebarContextProvider>
          <AppNavigation />
          <div id={MODAL_ROOT_ID} />
          <div className={css.mainContent}>
            <TopRibbon />
            <div className='container-fluid container-lg'>
              <Outlet />
            </div>
          </div>
          <FileUploadProgressModal />
        </SidebarContextProvider>
      </NotificationsProvider>
    </AuthContextProvider>
  );
};

export const AuthRouteWithoutNav: React.FC = () => (
  <AuthContextProvider>
    <NotificationsProvider>
      <div id={MODAL_ROOT_ID} />
      <Outlet />
    </NotificationsProvider>
  </AuthContextProvider>
);
