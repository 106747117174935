import { LYG_SUBDOMAIN } from 'src/context/routes';
import { getNonRefValue } from 'src/shared/utils/common';

export const TODAY = new Date();

export function dateIsValid(date?: Date | null | string): date is Date {
  return date !== null && date !== undefined && !Number.isNaN(new Date(date).getTime());
}

export function dateFromString(date: undefined | null | string | Date): Date | null {
  if (dateIsValid(date)) {
    const newDate = new Date(date);
    return newDate;
  }
  return null;
}

/**
 * Prefix a number with zeros until it reaches the specified target digit length.
 * @note If the number has more digits than the target digit length, then the number is returned as-is.
 * @example For 'targetDigitLength = 3', number '7' is returned '007'.
 */
export function numberWithZeroPadding(n: number, targetDigitLength: number): string {
  const numberDigitLength = n.toString().length;
  if (numberDigitLength >= targetDigitLength) {
    return String(n);
  }

  return (
    Array.from({ length: targetDigitLength - numberDigitLength })
      .map(() => '0')
      .join('') + String(n)
  );
}

/**
 * Returns stringified date without timezone offset; e.g. '2021-09-17 09:13:57' instead of '2021-09-17T09:13:57.645Z'
 */
export function dateToISONoTimezone(date: Date, includeTime?: boolean): string {
  const timeString = includeTime
    ? `${numberWithZeroPadding(date.getUTCHours(), 2)}:${numberWithZeroPadding(
        date.getUTCMinutes(),
        2,
      )}:${numberWithZeroPadding(date.getUTCSeconds(), 2)}`
    : '';
  return `${numberWithZeroPadding(date.getUTCFullYear(), 2)}-${numberWithZeroPadding(
    date.getUTCMonth() + 1,
    2,
  )}-${numberWithZeroPadding(date.getUTCDate(), 2)} ${timeString}`.trim();
}

export interface StartEndDates {
  endDate: Date;
  startDate: Date;
}

export function getMonthDates(date = new Date()): StartEndDates {
  return {
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
  };
}

export function getPrevMonthDates(date = new Date()): StartEndDates {
  const year = date.getFullYear();
  const month = date.getMonth();

  return {
    startDate: new Date(year, month - 1, 1),
    endDate: new Date(year, month, 0),
  };
}

export function getNextMonthDates(date = new Date()): StartEndDates {
  const year = date.getFullYear();
  const month = date.getMonth();

  return {
    startDate: new Date(year, month + 1, 1),
    endDate: new Date(year, month + 2, 0),
  };
}

export function getWeekDates(date = new Date()): StartEndDates {
  const dayOfWeek = date.getDay() || 7;
  const weekStart = new Date(date);
  weekStart.setHours(-24 * (dayOfWeek - 1));

  const startDate = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate());
  const endDate = new Date(startDate);
  endDate.setHours(24 * 6);

  return { endDate, startDate };
}

export function getYearDates(date = new Date()): StartEndDates {
  return {
    endDate: new Date(date.getFullYear(), 12, 0, 0, 0, 0, 0),
    startDate: new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0),
  };
}

export function getTodayDates(): StartEndDates {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    endDate: today,
    startDate: today,
  };
}

export function getAllTimeDates(): StartEndDates {
  // this is to prevent expiring the querys cache
  const date = new Date();
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return {
    endDate: today,
    startDate: new Date('01/01/2010'),
  };
}

export function datesOfPreviousPeriod(startDate: Date, endDate: Date): { startDate: Date; endDate: Date } {
  const prevEndDate = new Date(startDate);
  prevEndDate.setHours(-24);

  return {
    endDate: prevEndDate,
    startDate: new Date(prevEndDate.getTime() - (endDate.getTime() - startDate.getTime())),
  };
}

export enum PERIOD_OPTIONS {
  thisSeason = 'thisSeason',
  lastSeason = 'lastSeason',
  last30 = 'last30',
  last90 = 'last90',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

export const readableDatePeriods = new Map<PERIOD_OPTIONS, string>([
  [PERIOD_OPTIONS.thisSeason, 'This season'],
  [PERIOD_OPTIONS.lastSeason, 'Last season'],
  [PERIOD_OPTIONS.thisYear, 'This year'],
  [PERIOD_OPTIONS.lastYear, 'Last year'],
  [PERIOD_OPTIONS.last30, 'Last 30 days'],
  [PERIOD_OPTIONS.last90, 'Last 90 days'],
]);

const isLYG = window.location.hostname.startsWith(import.meta.env.PROD ? LYG_SUBDOMAIN : 'test');

const seasonStartMonth = isLYG ? 8 : 4;
const seasonEndMonth = isLYG ? 6 : 3;

const getSeasonStart = (year: number) => new Date(year, seasonStartMonth, 1, 2, 0, 0, 0);

const getSeasonEnd = (year: number) => {
  const newEndDate = new Date(year, seasonEndMonth, 30, 2, 0, 0, 0);
  return newEndDate;
};

export function startEndDatesFromPeriod(period: PERIOD_OPTIONS): StartEndDates {
  const today = new Date();
  today.setUTCHours(2, 0, 0, 0);

  let dates: StartEndDates = { endDate: today, startDate: today };
  let thisYear = today.getUTCFullYear();
  const currentYearSeasonEnd = new Date(thisYear, seasonEndMonth, 30, 2, 0, 0, 0);
  const isSeasonPeriod = period === PERIOD_OPTIONS.thisSeason || period === PERIOD_OPTIONS.lastSeason;

  if (isSeasonPeriod && today > currentYearSeasonEnd) {
    thisYear++;
    today.setUTCFullYear(thisYear);
  }
  const lastYear = thisYear - 1;

  switch (period) {
    case PERIOD_OPTIONS.last30:
      dates.startDate = new Date(getNonRefValue(today).setDate(today.getDate() - 30));
      break;
    case PERIOD_OPTIONS.last90:
      dates.startDate = new Date(getNonRefValue(today).setDate(today.getDate() - 90));
      break;
    case PERIOD_OPTIONS.thisSeason:
      dates = { startDate: getSeasonStart(lastYear), endDate: getSeasonEnd(thisYear) };
      break;
    case PERIOD_OPTIONS.thisYear:
      dates = getYearDates();
      break;
    case PERIOD_OPTIONS.lastSeason:
      dates = { startDate: getSeasonStart(lastYear - 1), endDate: getSeasonEnd(lastYear) };
      break;
    case PERIOD_OPTIONS.lastYear:
      dates = getYearDates(new Date(getNonRefValue(today).setFullYear(lastYear)));
      break;
  }
  return dates;
}

export function getDeltaAmountOfDays(startDate: Date, endDate: Date): number {
  const delta = endDate.getTime() - startDate.getTime();
  return Math.ceil(delta / (1000 * 60 * 60 * 24));
}

/**
 * Converts a date string like "Fri 30 Aug 10:40 AM" to "Fri Aug 30 2024 10:40:00 GMT+0100 (West Africa Standard Time)"
 */
export function convertToDateFormat(inputString: string): Date {
  // Define an object to map month names to month numbers
  const months: Record<string, number> = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  // Split the input string by spaces
  const parts = inputString.split(' ');

  // Extract day, month, time, and period from the input string
  const day: number = parseInt(parts[1], 10);
  const month: number = months[parts[2]];
  const time: string = parts[3];
  const period: string = parts[4];

  // Split time into hours and minutes
  const timeParts: number[] = time.split(':').map(Number);

  // Ensure that we have exactly two time parts (hours and minutes)
  if (timeParts.length !== 2) {
    throw new Error('Invalid time format');
  }

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timeParts;

  // Adjust hours based on AM/PM
  if (period === 'PM' && hours < 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  // Get the current year and set up the new date
  const currentYear: number = new Date().getFullYear();
  const date: Date = new Date(currentYear, month, day, hours, minutes);

  return date; // Format the date to the desired output
}
