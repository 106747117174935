import React, { PropsWithChildren } from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { InputFieldModel } from 'src/shared/ui/inputs/InputFieldModel';
import { useI18nContext } from 'src/context/i18n';
import css from './FormFieldWrapper.module.scss';

interface FormFieldWrapperProps {
  className?: string;
  withTitleMargin?: boolean;
  model: InputFieldModel<unknown>;
  titleExtraContent?: React.ReactNode;
  sportName?: string;
}

export const FormFieldWrapper = observer<PropsWithChildren<FormFieldWrapperProps>, HTMLDivElement>(
  ({ children, className, model, withTitleMargin, titleExtraContent = false, sportName = '', ...rest }, ref) => {
    const { errorMessage, hideAsterisk, errorIsVisible, required, title } = model;
    const i18n = useI18nContext();

    const customSportLabelMap: Record<string, Record<string, string>> = {
      Badminton: {
        'Period 1': 'Boys singles',
        'Period 2': 'Girls singles',
        'Period 3': 'Boys doubles',
        'Period 4': 'Girls doubles',
        'Period 5': 'Mix',
      },
      Squash: {
        'Period 1': 'Boys singles',
        'Period 2': 'Girls singles',
        'Period 3': 'Boys doubles',
        'Period 4': 'Girls doubles',
        'Period 5': 'Mix',
      },
      'Table Tennis': {
        'Period 1': 'Double',
        'Period 2': 'Single 1',
        'Period 3': 'Single 2',
      },
    };

    return (
      <div className={cx('text-break', css.wrapper, className)} {...rest} ref={ref}>
        {(title || withTitleMargin) && (
          <label className={cx('d-block', css.title)}>
            {Object.keys(customSportLabelMap).includes(sportName) ? customSportLabelMap[sportName][title] : title}&nbsp;
            {required && !hideAsterisk && title !== 'Venue' && (
              // {required && !hideAsterisk && (
              <span
                aria-label={i18n.t('shared.fields.requiredField')}
                className={cx('fw-bolder ms-1', css.required)}
                title={i18n.t('shared.fields.requiredField')}
              >
                *
              </span>
            )}
            {titleExtraContent ? titleExtraContent : null}
          </label>
        )}

        {children}

        {errorIsVisible && errorMessage && <div className={cx('mt-2 ts-fs-13', css.errors)}>{errorMessage}</div>}
      </div>
    );
  },
  { forwardRef: true },
);
