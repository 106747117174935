import { PLAYER_POSITION } from 'src/entities/players/domain';
import { PlayerGuardian } from 'src/pages/players/edit/PlayerGuardianModel';

export enum INVITED_PLAYER_STATUS {
  pending = 'pending',
  waitingPayment = 'waiting_payment',
}

export const invitedPlayerStatusMap = new Map<INVITED_PLAYER_STATUS, string>([
  [INVITED_PLAYER_STATUS.pending, 'Pending'],
  [INVITED_PLAYER_STATUS.waitingPayment, 'Awaiting payment'],
]);

export interface InvitedPlayer {
  created: Date;
  email: string;
  firstName: string;
  lastName: string;
  guardianEmail?: string;
  id: number;
  isMinor: boolean;
  teamName?: string;
  profileAvatar: string | null;
  status: INVITED_PLAYER_STATUS;
  inviteId: number;
  dob: string;
}

export interface PlayerListItem {
  avatarUrl?: string;
  created?: Date;
  email: string;
  firstName: string;
  id: number;
  rating: number;
  lastLogin?: Date;
  lastName: string;
  teamId?: number;
  teamName?: string;
  jersey?: number | null;
  position: PLAYER_POSITION | null;
  registrationNumber: string | null;
  registrationFile: string | null;
  guardians: PlayerGuardian[];
  phoneNumber: string | null;
}
